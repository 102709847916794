import React from "react"
import { css } from '@emotion/css'
import { Spring } from 'react-spring/renderprops'
import Container from "./Container"

const TopSectionMobile = (props) => (
    <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {Sprops => <div style={Sprops}>
            <div>
                <div style={{
                    width: '100vw',
                    height: props.big ? `500px` : `300px`,
                    backgroundImage: `url(${props.imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                    <div
                        className={css`
                width: 100%;
                height: 100%;
                background: rgb(255,255,255);
                background: ${props.lessGradient ? `linear-gradient(180deg, rgba(255,255,255,0) 72%, rgba(255,255,255,1) 94%)` : `linear-gradient(180deg, rgba(255,255,255,0.35272548472514) 52%, rgba(255,255,255,1) 87%)`};
                `}
                    >
                    </div>
                </div>
                <div style={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '70px',
                }}>
                    <Container>
                        {props.children}
                    </Container>
                </div>
            </div>
        </div>}
    </Spring>
)

export default TopSectionMobile