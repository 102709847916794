import React from "react"
import Section from "./Section"

const BlueSection = (props) => (
    <Section
        imageUrl={props.long ? "/img/blue-bg.png" : props.short ? "/img/blue-case-desktop.png" : "/img/blue-about-desktop.png"}
        imageUrlMobile={props.long ? `/img/blue-automation-mobile.png` : props.short ? "/img/blue-case-assistant-mobile.png" : `/img/blue-career-mobile.png`}
        extraMargin={props.extraMargin ? props.extraMargin : "-36px"}
        smallPadding={props.smallPadding ? true : false }
    >
        {props.children}
    </Section>
)

export default BlueSection