import React from "react"
import { css } from '@emotion/css'
import Layout from "../components/shared/Layout2"
import { graphql } from 'gatsby';
import Section from "../components/shared/Section"
import BigTitle from "../components/shared/BigTitle"
import SectionTitle from "../components/shared/SectionTitle"
import LightText from "../components/shared/LightText"
import WhiteOverlayHero from "../components/shared/WhiteOverlayHero"
import BlueSection from "../components/shared/BlueSection"
import TopSectionMobile from "../components/shared/TopSectionMobile"
import DownArrow from "../components/shared/DownArrow"
import Container from "../components/shared/Container"
import EndSection from "../components/shared/EndSection"
import MainButton from "../components/shared/MainButton"
import SEO from "../components/shared/seo"
import { Link, Element } from 'react-scroll'
import NavbarNew from "../components/shared/NavbarNew"
import { LangugaeContext } from "../context/LanguageProvider";

class JobTemplate extends React.Component {

    static contextType = LangugaeContext;

    render() {
  
      const svData = this.props.data.svJobs.edges
      const enData = this.props.data.enJobs.edges
      
      let post = null;

      const isBrowser = typeof window !== "undefined"
      if (isBrowser) {
        post = this.context.getCorrectPost(svData, enData, window)
      }

      return (post &&
    <Layout>
        <NavbarNew darkMode />
        <SEO 
            title={post.seo.title == "-" ? "" : post.seo.title}
            description={post.seo.descr == "-" ? "" : post.seo.descr}
          />

        <div className={css`
            display: none;
            @media (max-width: 1000px) {
                display: block;
            }
        `}>
            <TopSectionMobile imageUrl="/img/bg-career.jpg">
                <SectionTitle text={post.title} />
                {post.header?.map(block => {
                return <LightText text={block.text} />
            })}
                <Link to="firstSection" spy={true} smooth={true} duration={800}>
                    <DownArrow />
                </Link>
            </TopSectionMobile>
        </div>

        <div className={css`
            @media (max-width: 1000px) {
                display: none;
            }
        `}>
            <WhiteOverlayHero imageUrl={'/img/bg-career.jpg'}>
            <SectionTitle text={post.title} />
            {post.header?.map(block => {
                return <LightText text={block.text} />
            })}
                <Link to="firstSection" spy={true} smooth={true} duration={800}>
                    <DownArrow />
                </Link>
            </WhiteOverlayHero>
        </div>

        <Element name="firstSection" >
            <BlueSection long>
                <Container>
                    <SectionTitle text={post.bodyTitle}
                    />
                    {post.about?.map(block => {
                        return <LightText 
                            bold={block.boldText} 
                            text={block.text == "-" ? null : block.text}
                        />
                    })}
                    <a id="apply-button-analyst" className="mainButton flexCenter" href={'mailto:' + post.button?.path}>{post.button.text}</a>
                    {/*<MainButton to={post.button?.path} text={post.button.text} />*/}
                </Container>
            </BlueSection>
        </Element>

        <Section imageUrl={'/img/pink.png'}>
            <Container>
                <BigTitle text={post.pink.title} center />
                <MainButton to={post.pink.buttonPath} text={post.pink.buttonText} />
            </Container>
        </Section>

        <EndSection iconUrl={post.end.icon?.childImageSharp?.fluid?.src} to={post.end.buttonPath} title={post.end.title} buttonText={post.end.buttonText} />

    </Layout>
)}}

export default JobTemplate

export const pageQuery = graphql
`query {
    svJobs: allMdx(
      filter: {
        frontmatter: {templateKey: {eq: "sv-jobs"}},
    }, 
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            seo {
              title
              descr
            }
            header {
                text
            }
            bodyTitle
            about {
                boldText
                text
            }
            button {
                text
                path
            }
            pink {
                title
                text
                buttonText
                buttonPath
            }
            end {
                title
                icon {
                    childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                buttonText
                buttonPath
            }
          }
        }
      }
    },

    enJobs: allMdx(
        filter: {
          frontmatter: {templateKey: {eq: "en-jobs"}},
      }, 
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              seo {
                title
                descr
              }
              header {
                  text
              }
              bodyTitle
              about {
                  boldText
                  text
              }
              button {
                  text
                  path
              }
              pink {
                  title
                  text
                  buttonText
                  buttonPath
              }
              end {
                  title
                  icon {
                      childImageSharp {
                      fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  buttonText
                  buttonPath
              }
            }
          }
        }
      },

}`