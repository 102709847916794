import React from "react"
import { css } from '@emotion/css'
import { Spring } from 'react-spring/renderprops'
import Container from "./Container"

const WhiteOverlayHero = (props) => (
    <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}>
        {Sprops => <div style={Sprops}>
            <div style={{
                width: '100%',
                height: 'auto',
                backgroundImage: `url(${props.imageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                color: 'white',
            }}>
                <div
                    className={css`
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 150px 10px;

                background: rgb(255,255,255);
                background: linear-gradient(180deg, rgba(255,255,255,0.1801764651172969) 0%, rgba(255,255,255,0.5123893502713585) 22%, rgba(255,255,255,1) 73%);
            `}
                >
                    <Container marginTop>
                        {props.children}
                    </Container>
                </div>
            </div >
        </div>}
    </Spring>
)

export default WhiteOverlayHero