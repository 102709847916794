import React from "react"
import { css } from '@emotion/css'

const BigTitle = (props) => (
  <h1
    className={css`
    font-size: 6rem;
    font-weight: 600;
    color: ${props.color ? props.color : `var(--white);`};
    margin-bottom: 1.5em;
    text-align: ${props.center ? `center` : null};
    @media (max-width: 565px) {
      font-family: Avenir;
      font-size: 4rem;
    }
  `}
  >
    {props.text}
  </h1>
)

export default BigTitle