import React from "react"
import Rotate from 'react-reveal/Rotate';

const DownArrow = () => (
    <Rotate duration={2500}>
        <img
            src="/img/arrow.png"
            alt="big icon"
            style={{
                width: '30px',
                marginTop: '40px',
                cursor: 'pointer',
            }}
        />
    </Rotate>
)

export default DownArrow